import api from '../Http'

const baseUrl = '/management'

export async function getGroups() {
  return (await api()).get(`${baseUrl}/groups/tree`)
}

export const getGroupPeople = async data => {
  return (await api()).get(
    `/management/groups/${data.groupID}/people?role=${data.role}&module=${data.module}`
  )
}

export async function getGroupHierarchy(groupID) {
  return (await api()).get('/management/groups/:groupID/hierarchy', {
    _params: { groupID },
  })
}

export async function getGroupByID(groupID) {
  return (await api()).get('/management/groups/:groupID', {
    _params: { groupID },
  })
}

export const getGroupRoles = async data => {
  return (await api()).get(`management/groups/roles`, { params: data })
}

export const getPeopleRoles = async (data = { limit: 500 }) =>
  (await api()).get('/management/people/roles', { params: data })
