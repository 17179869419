import i18n from '@/plugins/i18n'

import { getMetadata } from '@/services/metadata'
import { getGroups } from '@/services/management'
import { getPeople } from '@/services/people'
import { getTags } from '@/services/tags'
import {
  getEntitiesAccessProfiles,
  getEntitiesWorkflowStatus,
  getOKREntities,
} from '@/services/entitiesOKR'
import { getStatusProgress } from '@/services/initiative'

import { hidratePeopleFn, hidrateSelectItems } from '@/helpers/okrs/metadatas'

function constructFilterItemSelector(filter) {
  return {
    type: 'ItemSelector',

    submit: {
      type: 'ArrayOf',
      identifier: 'id',
    },

    ItemSelector: {
      localSearch: filter.localSearch,

      menuConfig: {
        align: 'right',
        attach: false,
        showTabs: false,
      },

      inputConfig: {
        closeOnSelect: !filter.multiple,

        chipMode: ['tagIDs'].includes(filter.id),
        showAvatar: ['peopleGroupIDs', 'ownerIDs', 'personIDs'].includes(
          filter.id
        ),

        showElements: !['entityType', 'entityClass'].includes(filter.id),

        showCaretArrow: !['entityIDs'].includes(filter.id),
        clearable: true,
        ...(['entityIDs'].includes(filter.id)
          ? {
              tooltipConfig: {
                moreTooltip: {
                  linebreak: true,
                },
              },
            }
          : {}),

        itemsToPresent: 2,
      },

      ...(['entityIDs'].includes(filter.id)
        ? {
            itensComponent: {
              name: 'ListOfItems',
            },
            highlightQuantity: 8,
          }
        : {}),

      ...(['tagIDs'].includes(filter.id)
        ? {
            viewConfig: {
              chipMode: true,
              subheader: {
                show: true,
                label: i18n.t('comboChips.selectOnlyHeader'),
              },
            },
            highlightQuantity: 6,
          }
        : {}),

      type: filter.type,
      multiple: filter.multiple,
    },
  }
}

function constructFilterCardSelect(filter) {
  return {
    type: 'CardSelect',

    display: {
      label: true,
      customLabel: filter._label,
    },
    CardSelect: {
      mandatory: true,
      borderMode: false,
    },
  }
}

function constructFilterChipsSelection(filter) {
  return {
    type: 'ChipsSelection',

    ChipsSelection: {
      canUpdateItem: false,
      canCreateItem: false,
      placeholder: filter._label,
    },
  }
}

function constructFilterMetadataSelector() {
  return {
    type: 'MetadataSelector',
  }
}

function getConstructor(filterID) {
  const FiltesTypesByID = {
    status: 'ItemSelector',
    entityIDs: 'ItemSelector',
    entityType: 'ItemSelector',
    entityClass: 'ItemSelector',
    invitationStatus: 'ItemSelector',
    ownerIDs: 'ItemSelector',
    peopleGroupIDs: 'ItemSelector',
    personIDs: 'ItemSelector',
    accessProfileIDs: 'ItemSelector',
    workflowStatusIDs: 'ItemSelector',
    directGroupOnly: 'CardSelect',
    deleted: 'CardSelect',
    hasInactivePerson: 'CardSelect',
    tagIDs: 'ItemSelector',
    metadata: 'MetadataSelector',
  }
  const type = FiltesTypesByID[filterID]

  const constructorsByType = {
    ItemSelector: constructFilterItemSelector,
    CardSelect: constructFilterCardSelect,
    ChipsSelection: constructFilterChipsSelection,
    MetadataSelector: constructFilterMetadataSelector,
  }
  const construct = constructorsByType[type]

  if (!construct) {
    return null
  }

  return construct
}

export function hidrateFnByEntity(item) {
  const calcLabel = item => {
    let label = []

    if (item.entityType) {
      label.push(
        i18n.t(`entityFilters.options.entityTypeShort.${item.entityType}`)
      )
    }

    if (item.entityClass) {
      label.push(
        i18n.t(`entityFilters.options.entityClass.${item.entityClass}`)
      )
    }

    return label.join(' ')
  }

  const label = `${item.label} (${calcLabel(item)})`

  return {
    ...item,
    label: label,
  }
}

function constructFilterApi(filterID) {
  const payloadByID = {
    status: {
      endpoint: getStatusProgress,
      infinity: false,
      hidrateFn: item => ({
        ...item,
        id: item.value,
      }),
    },
    entityIDs: {
      endpoint: getOKREntities,
      searchKey: 'text',
      infinity: true,
      identifier: 'id',
      hidrateFn: item => ({
        ...item,
        label: `${item.code} - ${item.title}`,
      }),
    },
    ownerIDs: {
      endpoint: getPeople,
      searchKey: 'name',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: hidratePeopleFn,
      payload: {
        showInactive: true,
      },
    },
    peopleGroupIDs: {
      endpoint: getGroups,
      searchKey: null,
    },
    personIDs: {
      endpoint: getPeople,
      searchKey: 'name',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: hidratePeopleFn,
      payload: {
        showInactive: true,
      },
    },
    accessProfileIDs: {
      endpoint: getEntitiesAccessProfiles,
      searchKey: 'label',
      infinity: true,
      identifier: 'id',
      hidrateFn: hidrateFnByEntity,
      payload: {
        withSystemicTypes: true,
      },
    },
    workflowStatusIDs: {
      endpoint: getEntitiesWorkflowStatus,
      searchKey: null,
      hidrateFn: hidrateFnByEntity,
    },
    tagIDs: {
      endpoint: getTags,
      searchKey: 'label',
      infinity: true,
      limit: 30,
      identifier: 'id',
    },
    metadata: {
      endpoint: getMetadata,
      searchKey: 'label',
      infinity: true,
      identifier: 'id',
      hidrateFn: item => {
        const withLabel = hidrateFnByEntity(item)
        const options = Array.isArray(item?.options)
          ? { options: hidrateSelectItems(item.options) }
          : {}

        return {
          ...withLabel,
          ...options,
        }
      },
    },
  }

  const payload = payloadByID[filterID] || null

  return {
    api: payload,
  }
}

function constructFilterOptions(filterID, label = null, type = null) {
  const payloadByID = {
    entityType: [
      {
        value: filterID,
        label,
        type,
        items: [
          {
            label: i18n.t('entityFilters.options.entityType.context'),
            id: 'context',
          },
          {
            label: i18n.t('entityFilters.options.entityType.objective'),
            id: 'objective',
          },
          {
            label: i18n.t('entityFilters.options.entityType.key-result'),
            id: 'key-result',
          },
          {
            label: i18n.t('entityFilters.options.entityType.initiative'),
            id: 'initiative',
          },
          {
            label: i18n.t('entityFilters.options.entityType.sub-indicator'),
            id: 'sub-indicator',
          },
          {
            label: i18n.t('entityFilters.options.entityType.kpi'),
            id: 'kpi',
          },
        ],
      },
    ],

    entityClass: [
      {
        value: filterID,
        label,
        type,
        items: [
          {
            label: i18n.t('entityFilters.options.entityClass.strategic'),
            id: 'strategic',
          },
          {
            label: i18n.t('entityFilters.options.entityClass.tatical'),
            id: 'tatical',
          },
          {
            label: i18n.t('entityFilters.options.entityClass.operational'),
            id: 'operational',
          },
        ],
      },
    ],

    invitationStatus: [
      {
        value: filterID,
        label,
        type,
        items: [
          {
            label: i18n.t('entityFilters.options.invitationStatus.requested'),
            id: 'requested',
          },
          {
            label: i18n.t('entityFilters.options.invitationStatus.accepted'),
            id: 'accepted',
          },
          {
            label: i18n.t('entityFilters.options.invitationStatus.rejected'),
            id: 'rejected',
          },
        ],
      },
    ],

    directGroupOnly: [
      {
        id: 'all',
        msg: i18n.t('entityFilters.fields.directGroupOnly.all'),
      },
      {
        id: 'only',
        msg: i18n.t('entityFilters.fields.directGroupOnly.only'),
      },
    ],

    deleted: [
      {
        id: false,
        msg: i18n.t('entityFilters.fields.deletedEntity.no'),
      },
      {
        id: true,
        msg: i18n.t('entityFilters.fields.deletedEntity.yes'),
      },
    ],

    hasInactivePerson: [
      {
        id: null,
        msg: i18n.t('entityFilters.fields.peopleInactiveOptions.no'),
      },
      {
        id: true,
        msg: i18n.t('entityFilters.fields.peopleInactiveOptions.yes'),
      },
    ],
  }

  const payload = payloadByID[filterID] || null
  return {
    options: payload,
  }
}

function constructFilterDefault(filterID) {
  const payloadByID = {
    directGroupOnly: 'all',
    deleted: false,
    hasInactivePerson: null,
  }

  const payload = payloadByID[filterID] || null
  return {
    default: payload,
  }
}

function constructFilterPreference(filterID, label) {
  const constructLabel = label => {
    if (label) {
      return i18n.t(`entityFilters.fields.${label}`)
    }

    return i18n.t(`entityFilters.fields.${filterID}`)
  }

  const payloadByID = {
    status: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
    entityIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    entityType: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
    entityClass: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
    invitationStatus: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
    ownerIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    peopleGroupIDs: {
      multiple: true,
      localSearch: true,
      type: 'treeview',
    },
    personIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    accessProfileIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    workflowStatusIDs: {
      multiple: true,
      localSearch: true,
      type: 'listview',
    },
    tagIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
  }

  const payload = payloadByID[filterID] || {}
  return {
    ...payload,
    id: filterID,
    _label: constructLabel(label),
  }
}

function calcFilter(filter) {
  const constructor = getConstructor(filter.id)
  if (!constructor) {
    return null
  }

  const constructorPayload = constructFilterPreference(filter.id, filter?.label)

  const apiPayload = constructFilterApi(filter.id)
  const optionsPayload = constructFilterOptions(
    filter.id,
    constructorPayload?._label,
    constructorPayload?.type
  )
  const defaultPayload = constructFilterDefault(filter.id)
  const payload = constructor(constructorPayload)

  return {
    id: filter.id,
    _label: constructorPayload._label,
    ...payload,
    ...optionsPayload,
    ...apiPayload,
    ...defaultPayload,
    eagerApi: !!filter?.eagerApi,
  }
}

export function handleFilters(filters) {
  return filters.map(filter => calcFilter(filter)).filter(Boolean)
}
