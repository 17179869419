import Vue from 'vue'

import { alertErrorBus } from '@/helpers/alerts'
import i18n from '@/plugins/i18n'

import {
  getAccount,
  getAccountMe,
  putAccountMe,
  getManagementAccountPerformanceSettings,
} from '@/services/account'

/**
 * @typedef {'free' | 'basic' | 'advanced' | 'enterprise'} PackageType
 */

/**
 * @type {import('vuex').Module}
 */
export const UserModule = {
  namespaced: true,

  state: () => ({
    account: {},
    managementAccountPerformanceSettings: {},
    client: {
      id: null,
      permissions: [],

      /**
       * @type {PackageType}
       */
      planID: 'basic',
      paymentMethod: 'other',
      engagement: null,
    },

    _profile: {},
    profile: {
      id: null,
      name: null,
      photo: null,
      email: null,
      groups: null,
      language: null,
    },
  }),

  getters: {
    managementAccountPerformanceSettings: state =>
      state.managementAccountPerformanceSettings,
    user: state => state.profile,
    profile: state => {
      return {
        id: state.profile.id,
        name: state.profile.name,
        photo: state.profile.photo,
        groups: state.profile.groups,
        email: state.profile.email,
      }
    },

    language: state => state.profile.language,

    planID: state => state.client.planID,
    paymentMethod: state => state.client.paymentMethod,
    permissions: state => state.client.permissions || [],
    engagement: state => state.client.engagement,

    isBasicPlanAdminUser: state => {
      const isAdmin = !!state.client?.isAdmin
      const isBasic = state.client.planID === 'basic'

      return isAdmin && isBasic
    },
  },

  actions: {
    logoutAccount({ commit, state }) {
      commit('SET_CLIENT', {
        id: null,
        permissions: [],

        planID: 'basic',
        paymentMethod: 'other',
        engagement: null,
      })

      commit('SET_PROFILE', {
        id: null,
        name: 'anonymous',
        photo: null,
        email: null,
        groups: null,
        language: state.profile.language,
      })

      commit('SET_ACCOUNT', {})
      commit('SET_PROFILE_', {})
    },

    async putAccountMe({ commit }, payload) {
      return putAccountMe(payload)
        .then(() => {
          commit('SET_PROFILE_LANGUAGE', payload.language)
        })
        .catch(error => {
          alertErrorBus(i18n.t('alerts.ManagementAccountMe.error.put'), error)
        })
    },

    async getAccountMe({ dispatch, commit, state }, payload) {
      if (!payload?.refresh && state.client?.id) {
        return Promise.resolve(state._profile)
      }

      return getAccountMe(payload?.params || {})
        .then(async ({ data }) => {
          commit('SET_CLIENT', {
            id: data.id,
            permissions: data.permissions,

            planID: data.planID,
            paymentMethod: 'other',
            engagement: data.engagement,
          })

          commit('SET_PROFILE', {
            id: data.id,
            name: data.name,
            photo: data.photo,
            email: data.email,
            groups: null,
            language: data.language,
          })

          commit('SET_PROFILE_', data)

          dispatch('getAccount', payload)
          dispatch('getManagementAccountPerformanceSettings', payload)

          return data
        })
        .catch(error => {
          alertErrorBus(i18n.t('alerts.ManagementAccountMe.error.get'), error)
        })
    },

    async getAccount({ commit, state }, payload) {
      if (!payload?.refresh && state.account) {
        return Promise.resolve(state.account)
      }

      return getAccount()
        .then(({ data }) => {
          commit('SET_ACCOUNT', data)

          return data
        })
        .catch(error => {
          alertErrorBus(i18n.t('alerts.ManagementAccount.error.get'), error)
        })
    },

    async getManagementAccountPerformanceSettings({ commit, state }, payload) {
      if (!payload?.refresh && state.managementAccountPerformanceSettings) {
        return Promise.resolve(state.managementAccountPerformanceSettings)
      }

      return getManagementAccountPerformanceSettings()
        .then(({ data }) => {
          commit('SET_MANAGEMENT_ACCOUNT_PERFORMANCE_SETTINGS', data)

          return data
        })
        .catch(error => {
          alertErrorBus('Erro ao obter configurações:', error)
        })
    },
  },

  mutations: {
    SET_ACCOUNT(state, payload) {
      Vue.set(state, 'account', payload)
    },

    SET_MANAGEMENT_ACCOUNT_PERFORMANCE_SETTINGS(state, payload) {
      Vue.set(state, 'managementAccountPerformanceSettings', payload)
    },

    SET_CLIENT(state, payload) {
      Vue.set(state, 'client', payload)
    },

    SET_PROFILE_(state, payload) {
      Vue.set(state, '_profile', payload)
    },

    SET_PROFILE(state, payload) {
      Vue.set(state, 'profile', payload)
    },

    SET_PROFILE_LANGUAGE(state, payload) {
      Vue.set(state.profile, 'language', payload)
    },
  },
}
