<script>
import { components } from '@teamculture/components'

export default {
  name: 'ComposeFilters',
  extends: components.ComposeForm,

  props: {
    activeCycleIDs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    _isOkrsInitiativeCoresponsibility() {
      return ['okrs', 'initiative', 'coresponsibility'].includes(this.formName)
    },
    _isNotifications() {
      return ['notification', 'notification-sending'].includes(this.formName)
    },
  },

  watch: {
    activeCycleIDs(oldCyclesIDs, newCyclesIDs) {
      if (oldCyclesIDs === newCyclesIDs) return

      this.handleSetAffectedDirtSearch_okrs()
    },

    formName(oldName, newName) {
      if (oldName === newName) return

      this.handleSetAffectedDirtSearch_okrs()
    },
  },

  methods: {
    resetComposeForm() {
      this.$emit('hot:loading', true)

      this.handleEagerFieldOptions()

      this.defineFieldsAsDefault()

      this.defineComposeFormAsPreferences()

      this.handleLazySubmit()
    },

    cleanEntityFilter() {
      this.$emit('hot:loading', true)

      this.defineFieldsAsDefault()

      this.setComposeFormPreferences(true)

      this.handleLazySubmit()

      this.handleSetAffectedDirtSearch('entityType')
    },

    hasTranslatedText(key) {
      if (key.includes(' ')) {
        return key
      }

      const path = {
        okrs: `entityFilters.fields.${key}`,
        initiative: `entityFilters.fields.${key}`,
        coresponsibility: `entityFilters.fields.${key}`,
        notification: `notificationCenterFilter.fields.${key}`,
        'notification-sending': `sendingCenterFilter.fields.${key}`,
      }[this.formName]
      const translated = this.$t(path)

      return translated !== path ? translated : key
    },

    calcDefaultEntityPayload() {
      const isInitiativeFilters = ['coresponsibility', 'initiative'].includes(
        this.formName
      )
        ? 'initiative'
        : null

      const FormEntityType = this.form.compose?.['entityType']?.[0]?.id
      const FormEntityClass = this.form.compose?.['entityClass']?.[0]?.id

      return {
        entityType: isInitiativeFilters || FormEntityType || null,
        entityClass: FormEntityClass || null,
      }
    },

    calcApiCustomPayload(id) {
      const payloads =
        {
          ...this.calcApiCustomPayload_okrs(),
          ...this.calcApiCustomPayload_notifications(),
        }[id] || {}
      return payloads
    },

    calcApiCustomPayload_notifications() {
      if (!this._isNotifications) return {}

      const calcDefaultCyclePayload = {
        activeCycleIDs: [this.form.compose?.['cycle']?.[0]?.id].filter(Boolean),
      }

      return {
        roleIDs: {
          ...calcDefaultCyclePayload,
        },
        accessProfileIDs: {
          ...calcDefaultCyclePayload,
        },
      }
    },

    calcApiCustomPayload_okrs() {
      if (!this._isOkrsInitiativeCoresponsibility) return {}

      const calcDefaultEntityPayload = this.calcDefaultEntityPayload()

      return {
        workflowStatusIDs: {
          ...calcDefaultEntityPayload,
        },
        accessProfileIDs: {
          ...calcDefaultEntityPayload,
          activeCycleIDs: this.activeCycleIDs,
        },
        metadata: {
          ...calcDefaultEntityPayload,
          cycleIDs: this.activeCycleIDs,
        },
        entityIDs: {
          cycleIDs: this.activeCycleIDs,
        },
      }
    },

    calcObjectOrNull(obj) {
      for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue
        }

        if ([undefined].includes(obj[key])) {
          delete obj[key]
        }
      }

      return obj
    },

    validSelectorCanSearch(selectorField) {
      if (
        this._isOkrsInitiativeCoresponsibility &&
        ['metadata', 'workflowStatusIDs'].includes(selectorField.id)
      ) {
        return true
      }

      if (['ItemSelector', 'ChipsSelection'].includes(selectorField.type)) {
        return !selectorField?.[selectorField.type]?.localSearch
      }

      return false
    },

    handleSetAffectedDirtSearch(fieldId) {
      if (
        this._isOkrsInitiativeCoresponsibility &&
        ['entityType', 'entityClass'].includes(fieldId)
      ) {
        this.handleSetAffectedDirtSearch_okrs()
      }

      if (this._isNotifications && ['cycle'].includes(fieldId)) {
        this.handleSetAffectedDirtSearch_notifications()
      }
    },

    handleSetAffectedDirtSearch_notifications() {
      if (!this._isNotifications) return

      this.dirtSearch.push('roleIDs', 'accessProfileIDs')
    },
    handleSetAffectedDirtSearch_okrs() {
      if (!this._isOkrsInitiativeCoresponsibility) return

      this.dirtSearch.push(
        ...['accessProfileIDs', 'workflowStatusIDs', 'metadata']
      )
    },
  },
}
</script>
